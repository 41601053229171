import jwtDecode from 'jwt-decode'

const currentUser = token => {
  if (token) {
    const decodedData = jwtDecode(token)
    return { username: decodedData.name, id: decodedData.user_id, firstname: decodedData?.firstname, surname: decodedData?.surname, gender: decodedData?.gender, wtid: decodedData?.wtid, role: decodedData?.role }
  }

  return null
}

const gon = {
  env: process.env.NODE_ENV,
  csrf_token: window.localStorage.getItem('csrf_token'),
  auth_token: window.localStorage.getItem('auth_token'),
  current_user: currentUser(window.localStorage.getItem('auth_token')),
  base_url: process.env.REACT_APP_API_URL,
  builder_io: {
    key: process.env.REACT_APP_BIO_KEY
  },
  domain_url:
    process.env.NODE_ENV === 'production' ? '' : process.env.REACT_APP_API_URL,
  env_vars: {
    sentry: {
      autoSessionTracking: true,
      dsn: process.env.REACT_APP_SENTRY_URL,
      environment: process.env.NODE_ENV,
      release: process.env.REACT_APP_VERSION || 1.0,
      _metadata: {
        sdk: {
          name: 'sentry.javascript.browser',
          packages: [{ name: 'npm:@sentry/browser', version: '6.11.0' }]
        }
      },
      beforeSend(event, hint) {
        // Filter out browser extension errors
        const errorMessage = hint?.originalException?.message || hint?.originalException || ''
        if (typeof errorMessage === 'string' && (
          errorMessage.includes('chrome-extension://') || 
          errorMessage.includes('moz-extension://') ||
          errorMessage.includes('safari-extension://') ||
          errorMessage.includes('safari-web-extension://')
        )) {
          return null
        }

        return event
      }
    },
    version: process.env.REACT_APP_VERSION || 1.0,
  },
  urls: {
    api: `${process.env.REACT_APP_API_URL}/api`,
    elastic_api: process.env.REACT_APP_ELASTIC_API_URL
  }
}

export { gon }

export const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
export const USE_IN_VIEW_OPTIONS = {
  threshold: 1,
  triggerOnce: true
}
export const FILTER_MIN_LIMIT = 20
export const FILTER_MAX_LIMIT = 100

export const PER_PAGE_LIMIT = 48

export const LB_BROWSER_EXTENSION_IDS = process.env?.REACT_APP_LB_BROWSER_EXTENSION_IDS?.split(',') || []
export const LB_APPLE_CLIENT_ID = process.env.REACT_APP_LB_APPLE_CLIENT_ID
export const LB_APPLE_REDIRECT_URI_ID = `${process.env.REACT_APP_LB_URL}/login`
export const LB_SAFARI_BROWSER_EXTENSION_ID = process.env.REACT_APP_LB_SAFARI_BROWSER_EXTENSION_ID
export const LB_URL = process.env.REACT_APP_LB_URL

export const LB_NID = window.localStorage.getItem('lbnid')
export const GLEAP_KEY = process.env.REACT_APP_GLEAP_KEY

export const EXTENSION_ACTIVATION_VIDEO = process.env.REACT_APP_EXTENSION_ACTIVATION_VIDEO

export const REACT_APP_BE_CDN_ENDPOINT = process.env.REACT_APP_BE_CDN_ENDPOINT

export const REACT_APP_BE_BASE_URL = process.env.REACT_APP_BE_BASE_URL

export const SHOW_BLACK_FRIDAY = false
export const LB_NATIVE_APP_VERSION = parseFloat(localStorage.getItem('lb_native_app_version') || 0)
export const LB_APP_TAB_BAR_VERSION = 5.4

export const REACT_APP_BRAZE_API_KEY = process.env.REACT_APP_BRAZE_API_KEY
export const REACT_APP_BRAZE_ENDPOINT = process.env.REACT_APP_BRAZE_ENDPOINT
export const SP_TRACKER = process.env.REACT_APP_SP_TRACKER
export const SP_APP_ID = process.env.REACT_APP_SP_APP_ID
export const SP_DOMAIN = process.env.REACT_APP_SP_DOMAIN