import React from 'react'
import { useHistory } from 'react-router-dom'

const InvalidRoute = () => {
  const history = useHistory()
  return (
    <div className='d-flex container-404-page align-self-center'>
      <div className='main'>
        <h4 className='title-404'>
          <div>Our feathers are</div>
          <div>a bit ruffled.</div>
        </h4>
        <p className='para-404 mb-0'> Sorry, but it looks like this <span>page is missing.</span></p>
        {/* <div className='d-flex align-items-center justify-content-center mt-2'>
          <a href="/" className='btn btn-homepage d-flex align-items-center justify-content-center'>Go to Homepage1</a>
        </div> */}
        {/* <button className='btn btn-homepage' onClick={() => history.push('/')}> Go to Homepage </button> */}
      </div>
    </div>
  )
}

export default InvalidRoute
