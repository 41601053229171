import { LB_BROWSER_EXTENSION_IDS } from '../../../constant'

export default function sendAuthToExtension (token = null) {
  if (LB_BROWSER_EXTENSION_IDS?.length) {
    try {
      if (typeof window.chrome === 'undefined' && typeof window.browser !== 'undefined') {
        window.chrome = window.browser
      }

      LB_BROWSER_EXTENSION_IDS.forEach((extId) => {
        window.chrome?.runtime?.sendMessage(extId, { type: token ? 'login' : 'logout', token }, function(response) {
          if (chrome.runtime.lastError) {
            // Ignore "Tab not found" errors
            // console.log('Extension message failed:', chrome.runtime.lastError.message);
            return;
          }
        })
      })
    } catch (e) {
      // Handle runtime.sendMessage errors
      if (e.message?.includes('Invalid call to runtime.sendMessage') || 
          e.message?.includes('Tab not found')) {
        // Silently ignore expected extension messaging errors
        return
      }
    }
  }
}
