import React from "react"
import { Route, Redirect } from "react-router-dom"
import { toast } from "react-toastify"
import sendAuthToExtension from './SendAuthToExtension'

const toastId = "private-route-error"
const containerId = "main"
const toastOptions =  {
  toastId,
  containerId
}

const UnprotectedRoute = ({
  render, component: Component, currentUser, enableNotification = true, redirectedRoute = "/", ...rest
}) => (
  <Route
    {...rest}
    render={
      props => {
        if (!currentUser) {
          return <Component {...rest} {...props} />
        }
          if (enableNotification) {
            toast.success(
              <div>You are already logged in.</div>,
              toastOptions
            )
          }
          sendAuthToExtension(window.localStorage.getItem('auth_token'))
          const fromExtension = window.location.href.indexOf('fromExtension') > -1
          if (fromExtension) {
            sessionStorage.setItem('fromExtension', true)
          }
          if (!sessionStorage.getItem('fromExtension') && !fromExtension) {
            localStorage.setItem('preferences', true)
          }
          
          if (redirectedRoute === '/') {
            window.location.href = '/'
            return null
          }

          return (
            <Redirect to={
              {
                pathname: redirectedRoute
              }
            } />
          )
      }
  } />
)

export default UnprotectedRoute
