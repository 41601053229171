import { LB_BROWSER_EXTENSION_IDS, LB_SAFARI_BROWSER_EXTENSION_ID } from '../../../constant'
import { isSafari, isIOS } from '../../apps/App/utilis/detectBrowser'

export default function sendNidToExtension () {
  const lbNid = window.localStorage.getItem('lbnid')
  const utmParams = window.localStorage.getItem('utmParams')

  if (lbNid) {
    try {
      if (typeof window.chrome === 'undefined' && typeof window.browser !== 'undefined') {
        window.chrome = window.browser
      }

      if (isIOS() && isSafari) {
        window.chrome?.runtime?.sendMessage(LB_SAFARI_BROWSER_EXTENSION_ID, { type: 'lb-nid', lbNid: lbNid, utmParams: utmParams }, function(response) {
          if (chrome.runtime.lastError) {
            // Ignore "Tab not found" errors
            return;
          }
        })
      } else if (LB_BROWSER_EXTENSION_IDS?.length) {
        LB_BROWSER_EXTENSION_IDS.forEach((extId) => {
          window.chrome?.runtime?.sendMessage(extId, { type: 'lb-nid', lbNid: lbNid, utmParams: utmParams }, function(response) {
            if (chrome.runtime.lastError) {
              // Ignore "Tab not found" errors
              return;
            }
          })
        })
      }
    } catch (e) {
      // Handle runtime.sendMessage errors
      if (e.message?.includes('Invalid call to runtime.sendMessage') || 
          e.message?.includes('Tab not found')) {
        // Silently ignore expected extension messaging errors
        return
      }
      console.log(e)
    }
  }
}