import React, { useEffect, useRef, useState } from 'react'
import * as braze from '@braze/web-sdk';

import { LB_NID } from '../../../../../constant';
import {
    birdAndArrow,
    infoFilled,
    permissionHeader,
    polygonWarn,
    postInstall2,
    birdAndArrowIos18,
    ios18ToolbarIcon,
    postInstall2Ios18
} from '../../../../components/Icons'
import { getIOSVersion } from '../../utilis/detectBrowser';


const PageBadge = ({ total, current }) => (
    <div className='page-badge'>
        <span className='badge-text'>{current}/{total}</span>
    </div>
)

const Permission = () => {
    const [showWalkthrough, setShowWalkthrough] = useState(false);
    const walkthroughRef = useRef(null);

    useEffect(() => {
        // Initialize Braze tracking
        braze.changeUser(LB_NID);
        braze.getUser().setCustomUserAttribute('EXTENSION_INSTALLED', 'Y');
        braze.requestImmediateDataFlush();
    }, []);

    const handleWalkthroughClick = () => {
        setShowWalkthrough(prev => !prev);
        if (!walkthroughRef.current) return;

        walkthroughRef.current.scrollIntoView({ 
            behavior: 'smooth', 
            block: showWalkthrough ? "end" : "start", 
            inline: "nearest" 
        });
    };

    const iosVersion = getIOSVersion();
    const isIOS18Plus = iosVersion?.major >= 18;
    const arrowIcon = isIOS18Plus ? birdAndArrowIos18 : birdAndArrow;
    const toolbarIcon = isIOS18Plus 
    ? `<img src=${ios18ToolbarIcon} style='height: 14px;width: 14px;' className='ext-icon' alt={'ext_icon'} />`
    : `<span className='small'>A</span>A`;
    const walkthroughImage = isIOS18Plus ? postInstall2Ios18 : postInstall2;
 

    const steps = [
        {
            number: 4,
            content: (
                <>
                    Once again... tap on <span className='bold' dangerouslySetInnerHTML={{ __html: toolbarIcon }}></span> in the search bar
                    <div className='sub-text' style={{ marginLeft: '12px' }}>
                        Can't find <span className='ml-1' dangerouslySetInnerHTML={{ __html: toolbarIcon }}></span>? It might be at the top instead
                    </div>
                </>
            )
        },
        {
            number: 5,
            content: (
                <>
                    Then tap <span className='bold'>Little Birdie</span> {' '}
                    <img src={polygonWarn} height={18} width={20} style={{ marginBottom: 8 }} />
                </>
            )
        },
        {
            number: 6,
            content: <>Select <span className='bold'>Always Allow</span></>
        },
        {
            number: 7,
            content: <>Select <span className='bold'>Always Allow on every website</span></>
        }
    ];


    //   const walkthroughImage = isIOS18Plus ? postInstall1Ios18 : postInstall1;

    return (
        <div className='be-view enable' style={{ marginTop: '-56px' }}>
            <div>
                <img src={permissionHeader} alt="header" style={{ height: '230px', width: '100%' }} />
                <div className='title header' style={{ top: '-15px' }}>
                    <span className='sub-text' style={{ fontWeight: '700' }}>Always Allow</span>
                    <div style={{ fontWeight: '700' }}>on every website</div>
                    <div className='sub-header'>
                        To compare prices while you shop, you need to grant permission to Little Birdie
                    </div>
                </div>
            </div>

            <PageBadge total={2} current={2} />
            
            <div className='enable-extension'>
                <div>
                    <ul className='steps'>
                        {steps.map(({ number, content }) => (
                            <li key={number} className='step'>
                                <span className='step-sub-title'>{number}.{' '}</span>
                                {content}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className='privacy-policy'>
                    We do not sell your personal information. Ever.
                    <div>It's all in our <a className='privacy-link' to="/privacy">privacy policy.</a></div>
                </div>

                <div onClick={handleWalkthroughClick} style={{ padding: '20px 0px', pointerEvents: 'auto' }}>
                    <img src={infoFilled} alt='info-icon' />
                    <span className='info-text'>
                        {showWalkthrough ? 'Hide' : 'Show'} walkthrough
                    </span>
                </div>

                <div className='pointer-box'>
                    <img src={arrowIcon} className='bird-arrow' />
                </div>
            </div>

            <div className='walkthrough-container' ref={walkthroughRef}>
                {showWalkthrough && <img src={walkthroughImage} className='walkthrough' />}
            </div>
        </div>
    );
};

export default Permission;
