import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { crossIcon } from './Icons'

export default function MobileAppForceUpdate() {
  const MIN_VERSION_REQUIRED = 5.12
  const [show, setShow] = useState(false)
  const [version, setVersion] = useState(null)
  const isReactNativeWebView = !!window.ReactNativeWebView
  const showGoToStore = localStorage.getItem('lb_go_to_store_implemented') === 'true'

  useEffect(() => {
    const nativeAppVersion = parseFloat(localStorage.getItem('lb_native_app_version') || 0)
    if (isReactNativeWebView && nativeAppVersion && nativeAppVersion < MIN_VERSION_REQUIRED) {
      setShow(true)
      setVersion(nativeAppVersion)
    }
  }, [isReactNativeWebView])

  if (!isReactNativeWebView) return null

  const goToStore = () => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'go-to-app-play-store' }))
  }

  return (
    <Modal
      show={show}
      fullscreen
      backdrop="static"
      keyboard={false}
      centered
      className={`force-update-modal version-${version}`}
    >
      <Modal.Header className="border-0 position-relative">
        <button
          type="button"
          className="close position-absolute"
          onClick={() => setShow(false)}
          style={{ right: 20, top: 20 }}
        >
          <img src={crossIcon} alt="Close" />
        </button>
      </Modal.Header>
      <Modal.Body
        className="d-flex flex-column align-items-center justify-content-center text-center px-4 py-5"
        style={{ minHeight: '90vh' }}
      >
        <div className="update-content" style={{ maxWidth: 400 }}>
          <img
            src="/assets/images/update-required.png"
            alt="Update Required"
            className="mb-5 animate-bounce"
            style={{
              width: 160,
              height: 160,
              objectFit: 'contain'
            }}
          />
          <h2
            className="mb-4 lb-budgee-text"
            style={{
              fontSize: 32,
              fontWeight: 700,
            }}
          >
            Time for an update!
          </h2>
          <p
            className="mb-5 lb-secondary-text"
            style={{
              fontSize: 18,
              lineHeight: 1.6
            }}
          >
            A new version of Little Birdie is available with exciting new features and important improvements. Update now to continue enjoying the best shopping experience!
          </p>
          {showGoToStore && (
            <button
              className="btn btn-secondary device-links rounded-pill"
              style={{ minWidth: 200 }}
              onClick={goToStore}
            >
              Go to store
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
