import React, { useRef, useState } from 'react'
import {
  extEnableV3,
  extHeader,
  ios18ToolbarIcon,
  infoFilled,
  birdAndArrow,
  birdAndArrowIos18,
  postInstall1,
  postInstall1Ios18
} from "../../../../components/Icons";
import { getIOSVersion } from '../../utilis/detectBrowser';

const PageBadge = ({ total, current }) => (
  <div className='page-badge'>
    <span className='badge-text'>{current}/{total}</span>
  </div>
)

const EnableExtension = () => {
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const walkthroughRef = useRef(null);

  const walkthroughClick = () => {
    setShowWalkthrough(!showWalkthrough);
    if (walkthroughRef?.current) {
      walkthroughRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: showWalkthrough ? "end" : "start", 
        inline: "nearest" 
      });
    }
  }

  const iosVersion = getIOSVersion();
  const isIOS18Plus = iosVersion?.major >= 18;
  const arrowIcon = isIOS18Plus ? birdAndArrowIos18 : birdAndArrow;
  const toolbarIcon = isIOS18Plus 
    ? `<img src=${ios18ToolbarIcon} style='height: 14px;width: 14px;' className='ext-icon' alt={'ext_icon'} /> icon`
    : `<span className='small'>A</span>A`;
  const walkthroughImage = isIOS18Plus ? postInstall1Ios18 : postInstall1;

  return (
    <>
      <div className='be-view enable' style={{ marginTop: '-56px' }}>
        <div>
          <img src={extHeader} alt="header" style={{ width: '100%' }} />
          <span className='title header' style={{ top: '18px' }}>
            Enable the <span className='sub-text'>extension</span> on Safari
          </span>
        </div>

        <PageBadge total={2} current={1} />
        <div className='enable-extension'>
          <div>
            <ul className='steps'>
              <li className='step'>
                <span className='step-sub-title'>1. </span> 
                Look for Birdie, tap on <span className='bold' dangerouslySetInnerHTML={{ __html: toolbarIcon }}></span> in the search bar and then go to
                <span className='bold'>"Manage extensions"</span>
                <div className='sub-text'>
                  Can't find <span className='ml-1' dangerouslySetInnerHTML={{ __html: toolbarIcon }}></span>? It might be at the top instead
                </div>
              </li>
              <li className='step' style={{ position: 'relative', marginTop: '20px' }}>
                <span className='step-sub-title'>2. </span> Toggle on "Little Birdie"
                <img src={extEnableV3} className='ext-enable' alt="enable extension" />
                <div>then select "done"</div>
              </li>
            </ul>
          </div>

          <div onClick={walkthroughClick} style={{ padding: '20px 0px', pointerEvents: 'auto' }}>
            <img src={infoFilled} alt='info-icon' />
            <span className='info-text'>{showWalkthrough ? 'Hide' : 'Show'} walkthrough</span>
          </div>

          <div className='pointer-box'>
            <img src={arrowIcon} className='bird-arrow' alt="arrow indicator" />
          </div>
        </div>

        <div className='walkthrough-container' ref={walkthroughRef}>
          {showWalkthrough && <img src={walkthroughImage} className='walkthrough' alt="walkthrough" />}
        </div>
      </div>
    </>
  )
}

export default EnableExtension
